<template>
  <Modal
    :dialog="isModalOpen"
    noPadding
    persistent
    id="reconnect-modal"
  >
    <template v-slot:content>
      <p class="defaultText--text mt-5 font-weight-medium">
        {{ $t('reconnectModal.title') }}
      </p>
      <p class="defaultText--text mt-5">
        {{ $t('reconnectModal.desc', { time: minutes }) }}
      </p>
      <CardNav
        :nextText="$t('reconnectModal.yes')"
        :prevText="$t('reconnectModal.no')"
        @next="refresh"
        @previous="logout"
        :nextLoading="loading"
        :prevDisabled="loading"
        class="mt-5"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Shared/Modal.vue';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'CompleteOnboardingModal',
  components: {
    Modal,
    CardNav,
  },
  data: () => ({
    isModalOpen: false,
    loading: false,
    refreshInterval: null,
    getUserInterval: null,
    afkInterval: null,
    minutes: 25,
  }),
  watch: {
    minutes(newVal) {
      if (newVal === 0) {
        clearInterval(this.afkInterval);
        this.isModalOpen = false;
        this.$router.push('/logout');
      }
    },
  },
  created() {
    this.createTimeout();
    window.onmousemove = () => {
      clearTimeout(this.getUserInterval);
      this.createTimeout();
    };
    window.onkeypress = () => {
      clearTimeout(this.getUserInterval);
      this.createTimeout();
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      this.afkInterval = setInterval(() => {
        this.minutes -= 1;
      }, 60000);
    },
    logout() {
      this.isModalOpen = false;
      this.$router.push('/logout');
    },
    async refresh() {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/refreshSession', this.$store.state.user.data.email);
        this.minutes = 25;
        clearInterval(this.afkInterval);
        this.isModalOpen = false;
      } catch (error) {
        this.$notify.error(error);
      }
      this.loading = false;
    },
    createTimeout() {
      this.getUserInterval = setTimeout(() => {
        this.openModal();
      }, 300000);
    },
  },
};
</script>
